import Pagination from 'components/blog/Pagination';
// import Layout from '../components/Layout';
import PostList from 'components/blog/PostList';
// import TermList from 'components/blog/TermList';
import { Layout, Seo } from 'components/common';
import config from 'data/config';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Intro } from 'components/sections';

const Posts = ({ data, pageContext }) => {
  const {
    allWpPost: { edges: posts },
    postsIntroImage,
  } = data;

  return (
    <Layout context={pageContext}>
      <Seo title="Blog" />
      <Intro
        title="Recent Posts"
        subtitle="I write about challenges faced in software development and life.  Where one stops and the other begins, you tell me."
        button={false}
        image={{
          alt: 'Recent Posts Header Image',
          image: postsIntroImage.childImageSharp.gatsbyImageData,
        }}
      />
      <PostList posts={posts} pathPrefix={`/${config.routes.post}`} />
      <Pagination
        pageContext={pageContext}
        pathPrefix={`/${config.routes.post}`}
      />
    </Layout>
  );
};

Posts.propTypes = {
  data: PropTypes.shape({
    allWpPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
};

export default Posts;

export const pageQuery = graphql`
  query PostsListQuery($limit: Int!, $skip: Int!) {
    allWpPost(sort: { fields: date, order: DESC }, limit: $limit, skip: $skip) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    postsIntroImage: file(
      relativePath: { eq: "illustrations/bomber-women.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: TRACED_SVG
          transformOptions: { cropFocus: SOUTH }
        )
      }
    }
  }
`;

// Header Images:
// akira-map.png
